import React, { useState, useEffect } from "react";
import config from "../Utils/config"; // Assuming config file is properly imported
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AdminBranch({ setTopBranches }) {
  // State hooks for loading, data, error, chart data, and top branches
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null); // To store chart data
  const [totalContracts, setTotalContracts] = useState(0); // To store the total contract count
  const [last7DaysContracts, setLast7DaysContracts] = useState(0); // To store last 7 days contract count

  // Helper function to filter the last 7 days of data
  const filterLast7Days = (data) => {
    const today = new Date();
    const last7Days = today.setDate(today.getDate() - 7);

    return data.filter((contract) => {
      const contractDate = new Date(contract.date); // assuming 'createdDate' is available
      return contractDate >= last7Days;
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Log the fetched data to the console
        console.log("Fetched Data:", data);
        setData(data);
        processBranchData(data); // Call function to process data for chart
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

 
  const processBranchData = (data) => {
    const branchCounts = {}; 
    const last7DaysBranchCounts = {};
    let total = 0;
    let last7DaysTotal = 0;

    // Filter the data for the last 7 days
    const last7DaysData = filterLast7Days(data);

    // Count occurrences of each branchId for all time
    data.forEach((item) => {
      const branchId = item.branchId;
      if (branchId) {
        branchCounts[branchId] = (branchCounts[branchId] || 0) + 1;
      }
    });

    // Count occurrences of each branchId for the last 7 days
    last7DaysData.forEach((item) => {
      const branchId = item.branchId;
      if (branchId) {
        last7DaysBranchCounts[branchId] = (last7DaysBranchCounts[branchId] || 0) + 1;
      }
    });

    // Calculate total contracts for all time and last 7 days
    total = Object.values(branchCounts).reduce((acc, count) => acc + count, 0);
    last7DaysTotal = Object.values(last7DaysBranchCounts).reduce(
      (acc, count) => acc + count,
      0
    );

    setTotalContracts(total); 
    setLast7DaysContracts(last7DaysTotal); 

    // Set the chart data for the last 7 days (Branch Counts in the last 7 days)
    const chartLabels = Object.keys(last7DaysBranchCounts).map(
      (branchId) => `Branch ${branchId}`
    );
    const chartValues = Object.values(last7DaysBranchCounts);

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "Branch Count (Last 7 Days)",
          data: chartValues,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    });

    // Get the top 3 branches based on the counts from the last 7 days (not all data)
    const sortedLast7DaysBranches = Object.entries(last7DaysBranchCounts)
      .sort((a, b) => b[1] - a[1]) 
      .slice(0, 3); 

    
    setTopBranches(sortedLast7DaysBranches);
  };

  // Conditional rendering for loading, error, and the chart
  return (
    <div>
      <h3>Branch Data</h3>
      {/* Display Total Contracts and Last 7 Days Contracts */}
      <p>Total Contracts (All Time): {totalContracts}</p>
      <p>Total Contracts (Last 7 Days): {last7DaysContracts}</p>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {/* Render Bar Chart if chartData is available */}
      {chartData && (
        <div style={{ width: "80%", margin: "0 auto", paddingTop: "20px" }}>
          <Bar
            style={{ marginTop: "80px", marginBottom: "0",}}
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: "Contract By Per Branch (Last 7 Days)",
                },
                legend: {
                  display: true,
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Branch IDs",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Count",
                  },
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AdminBranch;
