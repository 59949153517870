import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/ViewCustomer.css";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { PrintCustomerDetails } from "./Utils/PrintFeature";
import showToast from "./Utils/showToast";

function ViewCustomer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openPanels, setOpenPanels] = useState(["panel1"]); // Changed to track an array of open panels
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(false);
  const [signature, setSignature] = useState(null);

  // Fetch customer data based on id
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const url = `${config.apiBaseUrl}SearchContract/Detail/${id}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        setData(json);

        const signatureFromData = json[0]?.signature;
        if (signatureFromData && signatureFromData !== "") {
          setSignature(signatureFromData);
          setIsCanvasDisabled(true);
        }
      } catch (err) {
        setError(err.message);
        console.error("Fetch error: ", err);
      } finally {
        setLoading(false);
      }
    };

    const fetchContractsData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${config.apiBaseUrl}NewContract/GetContractByCustomerId/${id}`, // Corrected URL interpolation
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const contracts = await response.json();
          setContractData(contracts);
        } else {
          throw new Error("Failed to fetch contracts data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchData();
      fetchContractsData();
    } else {
      setError("ID is not provided or invalid");
    }
  }, [id]);

  console.log(contractData);

  //print function
  const handlePrint = () => {
    PrintCustomerDetails(data);
    showToast({
      type: "success",
      message: "PDF generated successfully!",
    });
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  // Function to handle opening and closing of accordion panels
  const handleAccordionChange = (panel) => {
    setOpenPanels((prev) => {
      if (prev.includes(panel)) {
        // If panel is already open, remove it (close it)
        return prev.filter((p) => p !== panel);
      } else {
        return [...prev, panel];
      }
    });
  };

  // Function to get styles for the accordion panels
  const getAccordionStyles = (panel) => {
    const isSelected = openPanels.includes(panel);
    return {
      background: isSelected
        ? "linear-gradient(to top, #4481eb 0%, #04befe 100%)"
        : "#f1f1f1",
      color: isSelected ? "#fff" : "#000",
    };
  };

  //accordion content
  const renderAccordionContent = (panel) => {
    switch (panel) {
      case "panel1":
        return (
          <>
            <p>
              <b> Name : </b>
              {data[0]?.firstName} {data[0]?.lastName}
            </p>
            <p>
              <b> Address : </b> {data[0]?.address}
            </p>
            <p>
              <b> Phone : </b>
              {data[0]?.phoneNo}
            </p>
            <p>
              <b> Email : </b>
              {data[0]?.emailAddress}
            </p>
          </>
        );
      case "panel2":
        return (
          <>
            <p>
              <b> Event Date : </b>
              {data[0]?.eventDate}
            </p>
            <p>
              <b> Event Time : </b>
              {data[0]?.timeSlot}
            </p>
            <p>
              <b> Event Venue : </b>
              {data[0]?.eventInfoVenue}
            </p>
          </>
        );
      case "panel3":
        return (
          <>
            <p>
              <b> Package Name : </b>
              {data[0]?.packageName}
            </p>
            <p>
              <b> CharacterName : </b>
              {data[0]?.characterName}
            </p>
            <p>
              <b> Addons : </b>
              {data[0]?.addons}
            </p>
            <p>
              <b> Bounces : </b>
              {data[0]?.bounces}
            </p>
          </>
        );

      default:
        return null;
    }
  };

  // Drawing functions
  const startDrawing = (e) => {
    if (isCanvasDisabled) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // Get the position of the canvas relative to the page
    const rect = canvas.getBoundingClientRect();
    // Adjust the starting point of the drawing
    ctx.beginPath();
    ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top);
    setIsDrawing(true);
  };

  let penWidth = 2; // Initial pen width

  // Draw function
  const draw = (e) => {
    if (isCanvasDisabled) return;
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineWidth = penWidth;
    // Get the position of the canvas relative to the page
    const rect = canvas.getBoundingClientRect();
    // Draw the line using the adjusted coordinates
    ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (isCanvasDisabled) return;
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    if (isCanvasDisabled) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clears the canvas
  };

  // Submit signature
  const handleSubmit = async () => {
    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d"); // Get canvas context
      const signature = canvas.toDataURL();

      // Check if the canvas is empty by checking pixel data (non-white pixels)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const canvasPixels = imageData.data;

      let isCanvasEmpty = true;
      for (let i = 0; i < canvasPixels.length; i += 4) {
        const alpha = canvasPixels[i + 3]; // alpha channel (opacity) - if it's > 0, the pixel is drawn
        if (alpha !== 0) {
          isCanvasEmpty = false;
          break;
        }
      }

      if (isCanvasEmpty) {
        alert("Please provide a signature before submitting.");
        return;
      }

      const formData = data[0];
      const customerId = parseInt(formData?.customerId, 10);
      const contractStatusId = 2;

      if (!customerId || !contractStatusId) {
        alert("Missing required data.");
        return;
      }

      const payload = {
        customerId: customerId,
        contractStatusId: contractStatusId,
        signature: signature,
      };

      const token = localStorage.getItem("token");

      const apiUrl = `${config.apiBaseUrl}ContractTimeTeamInfoes/updateContractStatus`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // Only log success message if everything is fine
      if (response.status === 200) {
        console.log("Signature submitted successfully.");
        setSignature(signature);
        showToast({
          type: "success",
          message: "Signature Submitted successfully!",
        });
        clearCanvas();
      } else {
        console.error(`Failed to submit signature. Status: ${response.status}`);
        alert("No contracts found for the specified customer ID");
      }
    } catch (error) {
      console.error("Error submitting signature:", error);
    }
  };

  function convertToNormalDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  }

  const handleEdit = (customerId, contractId) => {
    // Navigate to the customer page with customerId and contractId in the URL
    navigate(`/customer/${customerId}/contract/${contractId}`);
  };

  console.log(data);

  return (
    <div className="view-customer-container">
      <h1>Customer Details</h1>

      <div className="custom-accordion-container">
        <div className="custom-accordion-container-btn">
          <Button onClick={handlePrint} variant="contained" color="primary">
            Print
          </Button>
        </div>
        {["panel1", "panel2", "panel3"].map((panel) => (
          <Accordion
            key={panel}
            expanded={openPanels.includes(panel)} // Check if the panel is in the openPanels array
            onChange={() => handleAccordionChange(panel)}
            className="custom-accordion-heading"
          >
            <AccordionSummary
              style={getAccordionStyles(panel)}
              expandIcon={
                <ExpandMore
                  style={{
                    color: openPanels.includes(panel) ? "#fff" : "#000",
                  }}
                />
              }
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
            >
              {panel === "panel1" && "Personal Details"}
              {panel === "panel2" && "Event Information"}
              {panel === "panel3" && "Package Details"}
            </AccordionSummary>
            <AccordionDetails className="custom-accordion-para">
              {data.length > 0 && renderAccordionContent(panel)}
            </AccordionDetails>
          </Accordion>
        ))}

        {/*  canva and image container */}

        <div>
          {error && <div style={{ color: "red" }}>{error}</div>}

          <div className="sign-container">
            <div className="canva-container">
              {signature ? (
                <>
                  <h3 style={{ textAlign: "center" }}>Your Sign</h3>
                  <div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      <img
                        src={signature}
                        alt="Signature"
                        width={200}
                        height={100}
                      />
                    </div>
                  </div>
                </>
              ) : (
                // If signature doesn't exist, show canvas and buttons to allow drawing
                <>
                  <h3>Sign Below</h3>
                  <div>
                    <canvas
                      ref={canvasRef}
                      width={400}
                      height={200}
                      style={{
                        border: "1px solid black",
                        display: "flex",
                        margin: "10px auto",
                        pointerEvents: isCanvasDisabled ? "none" : "auto", // Disable canvas interaction if needed
                      }}
                      onMouseDown={startDrawing}
                      onMouseMove={draw}
                      onMouseUp={stopDrawing}
                      onMouseLeave={stopDrawing}
                    ></canvas>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        onClick={clearCanvas}
                        variant="contained"
                        sx={{ height: "100%", marginBottom: "10px" }}
                        color="primary"
                        disabled={isCanvasDisabled}
                      >
                        Clear
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ height: "100%", marginBottom: "10px" }}
                        color="primary"
                        disabled={isCanvasDisabled}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* contract table */}
      <div style={{ margin: "100px" }}>
      <Typography variant="h6" style={{ marginBottom: "10px", textAlign: "center" }}>
        Contract List
      </Typography>

      {/* Table Component */}
      <TableContainer style={{ margin: "0 auto", width: "50%" }} component={Paper}>
        <Table style={{ margin: "0" }}>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell>Contract Number</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Team No</TableCell>
              <TableCell>Time Slot</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {/* Check if contractList is empty */}
            {contractData?.contractList?.length > 0 ? (
              contractData.contractList.map((contract, index) => (
                <TableRow key={index}>
                  <TableCell>{contract.contractNo}</TableCell>
                  <TableCell>{convertToNormalDate(contract.createdDate)}</TableCell>
                  <TableCell>{contract.date}</TableCell>
                  <TableCell>{contract.teamId}</TableCell>
                  <TableCell>{contract.timeSlot.time}</TableCell>
                  <TableCell>
                    <button
                      className="action-button edit-button"
                      onClick={() => handleEdit(id, contract.contractId)}
                      title="Edit Contract"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              // Show a single row with "No Data" if contractList is empty
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                  No Contract Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </div>
  );
}

export default ViewCustomer;
