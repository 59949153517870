import React, { useState } from 'react';
import { TextField, Grid, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';

const AgentFilters = (props) => {
    const [searchData, setSearchData] = useState({
        userName: "",
        email: "",
        branch: "",
        status: "",
        createdDateFrom: "",
        createdDateTo: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleReset = () => {
        const resetData = {
            userName: "",
            email: "",
            branch: "",
            status: "",
            createdDateFrom: "",
            createdDateTo: ""
        };
        setSearchData(resetData);
        props.onSearchDataChange(resetData); // Reset filters in the parent component as well
    };

    const handleSearch = () => {
        props.onSearchDataChange(searchData);
    };

    return (
        <div className="form-container">
            <Grid container spacing={2} className="form-grid">
                <Grid item xs={12} md={3}>
                    <TextField
                        label="User Name"
                        variant="outlined"
                        fullWidth
                        name="userName"
                        value={searchData.userName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={searchData.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                {props.branches && (
                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Branch</InputLabel>
                            <Select
                                label="Branch"
                                name="branch"
                                value={searchData.branch}
                                onChange={handleInputChange}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {props.branches.map(branch => (
                                    <MenuItem key={branch.branchId} value={branch.branchName}>{branch.branchName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            label="Status"
                            name="status"
                            value={searchData.status}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Date From"
                        variant="outlined"
                        fullWidth
                        name="createdDateFrom"
                        type="date"
                        value={searchData.createdDateFrom}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Date To"
                        variant="outlined"
                        fullWidth
                        name="createdDateTo"
                        type="date"
                        value={searchData.createdDateTo}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid style={{gap:"20px", display:"flex"}} item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AgentFilters;
