import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "../Utils/config"; // Assuming you have a config file for API base URL

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ContractPerTeam({ setTopTeams }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data);
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  // Function to get the start and end date of the current week (Monday to Sunday)
  const getCurrentWeekDates = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - dayOfWeek + 1)
    ); // Set to Monday
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to Sunday of this week
    return { start: startOfWeek, end: endOfWeek };
  };

  // Process the data to group by teamNo and count occurrences
  useEffect(() => {
    if (data.length > 0) {
      // Get the start and end date for the current week
      const { start, end } = getCurrentWeekDates();

      // Filter the data to include only contracts from the current week
      const filteredData = data.filter((item) => {
        const contractDate = new Date(item.date); // Assuming you have contractDate in your data
        return contractDate >= start && contractDate <= end;
      });

      // Grouping and counting teamNo occurrences for the current week only
      const teamCount = filteredData.reduce((acc, item) => {
        const teamNo = item.teamNo;
        if (teamNo) {
          acc[teamNo] = (acc[teamNo] || 0) + 1;
        }
        return acc;
      }, {});

      // Prepare chart data for all teams (before sorting, all-time data)
      const allTimeTeamCount = data.reduce((acc, item) => {
        const teamNo = item.teamNo;
        if (teamNo) {
          acc[teamNo] = (acc[teamNo] || 0) + 1;
        }
        return acc;
      }, {});

      const chartLabels = Object.keys(allTimeTeamCount); // All teamNo values (before sorting)
      const chartValues = Object.values(allTimeTeamCount); // All count values

      // Set chart data for all-time counts
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Team Contract Count (All Time)",
            data: chartValues,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
          },
        ],
      });

      // Sort the teams by their counts for this week (descending order)
      const sortedTeams = Object.entries(teamCount).sort(
        ([, countA], [, countB]) => countB - countA
      ); // Sort by count in descending order

      // Extract the top 3 teamNos and their counts for this week
      const topTeamNosWithCounts = sortedTeams
        .slice(0, 3)
        .map(([teamNo, count]) => ({ teamNo, count }));

      setTopTeams(topTeamNosWithCounts);
    }
  }, [data, setTopTeams]);

  console.log("Data of per team:", data);
  // Show loading or error messages if needed
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div style={{ margin: "auto" }}>
        <h2>Contracts Per Team</h2>
        {chartData && (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: "Contract Count per Team (All Time)",
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            }}
          />
        )}
      </div>
    </>
  );
}

export default ContractPerTeam;
