import { jsPDF } from "jspdf";



//helper function to handle null or undefined
const getSafeValue = (value) => {
  return value === null || value === undefined ? "" : value;
};

// print feature to handle distance and duration as well
export const PrintFeature = (displayedData, distances, durations) => {
  if (displayedData.length === 0) {
    alert("No data available to generate PDF.");
    return;
  }

  const doc = new jsPDF();
  let currentY = 10;
  const marginBottom = 20;
  const pageHeight = doc.internal.pageSize.height;
  let lastTeamNo = null; // Variable to keep track of the last teamNo printed

  displayedData.forEach((customer, index) => {
    // Check if the teamNo has changed
    if (customer.teamNo !== lastTeamNo) {
      // If it's a new teamNo, add a heading
      if (currentY + 10 > pageHeight - marginBottom) {
        doc.addPage();
        currentY = 10;
      }
      
      doc.setFontSize(18);
      const text = `${getSafeValue(customer.teamNo)}`; // Apply getSafeValue here
      const pageWidth = doc.internal.pageSize.width;  
      const textWidth = doc.getTextWidth(text);       
      const xPosition = (pageWidth - textWidth) / 2;  
      
      doc.text(text, xPosition, currentY);  
      currentY += 10;  
      
      // Update the last teamNo to the current one
      lastTeamNo = customer.teamNo;
    }

    // Add customer data
    if (currentY + 30 > pageHeight - marginBottom) {
      doc.addPage();
      currentY = 10;
    }

    doc.setFontSize(12); // Set font size for the rest of the content
    doc.text(`Customer Name: ${getSafeValue(customer.firstName)} ${getSafeValue(customer.lastName)}`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`Date: ${getSafeValue(customer.entryDate)}`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`Address: ${getSafeValue(customer.address)}`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`Zip Code: ${getSafeValue(customer.zip)}`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`City: ${getSafeValue(customer.city)}`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`Distance: ${getSafeValue(distances[index])} km`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text(`Duration: ${getSafeValue(durations[index])} Min`, 10, currentY); // Apply getSafeValue here
    currentY += 10;
    doc.text("------", 10, currentY);
    currentY += 20;
  });

  doc.save("customer_data.pdf");
};


// Print feature
export const PrintCustomerDetails = (displayedData) => {
  if (displayedData.length === 0) {
    alert("No data available to generate PDF.");
    return;
  }

  const doc = new jsPDF();
  let currentY = 20; // Start a little lower for spacing
  const marginBottom = 20;
  const pageHeight = doc.internal.pageSize.height;

  // Set the font size and style for the heading
  doc.setFontSize(18);
  const heading = "Customer Details";
  const headingWidth = doc.getTextWidth(heading);
  const centerX = (doc.internal.pageSize.width - headingWidth) / 2;

  // Add the centered heading
  doc.text(heading, centerX, currentY);
  currentY += 15; // Add some space below the heading

  // Set font size for customer details
  doc.setFontSize(12);

  displayedData.forEach((customer, index) => {
    if (currentY + 30 > pageHeight - marginBottom) {
      doc.addPage();
      currentY = 10;
    }

    // Personal Information Section
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("- Personal Information : ", 10, currentY);
    currentY += 10;
    
    doc.setTextColor(0, 0, 0); // Reset text color
    doc.setFontSize(12); // Reset to normal size for the content

    doc.setDrawColor(211, 211, 211); // Light grey color for lines

    // Customer Details
    doc.text(`Customer Name: ${getSafeValue(customer.firstName)} ${getSafeValue(customer.lastName)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Date: ${getSafeValue(customer.entryDate)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Address: ${getSafeValue(customer.address)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Phone No: ${getSafeValue(customer.phoneNo)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Email: ${getSafeValue(customer.emailAddress)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 20;

    // Event Information Section
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("- Event Information : ", 10, currentY);
    currentY += 10;

    doc.setTextColor(0, 0, 0); // Reset text color
    doc.setFontSize(12); // Reset to normal size for the content

    // Event Details
    doc.text(`Event Date: ${getSafeValue(customer.eventDate)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Event Time: ${getSafeValue(customer.timeSlot)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Event Venue: ${getSafeValue(customer.eventInfoVenue)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 20;

    // Package Information Section
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("- Package Information : ", 10, currentY);
    currentY += 10;

    doc.setTextColor(0, 0, 0); // Reset text color
    doc.setFontSize(12); // Reset to normal size for the content

    // Package Details
    doc.text(`Package Name: ${getSafeValue(customer.packageName)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Character Name: ${getSafeValue(customer.characterName)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Addons: ${getSafeValue(customer.addons)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text(`Bounces: ${getSafeValue(customer.bounces)}`, 10, currentY);
    doc.line(10, currentY + 2, doc.internal.pageSize.width - 10, currentY + 2); // Add line below
    currentY += 10;

    doc.text("------", 10, currentY);
    currentY += 10;

    // Signature
    if (customer.signature) {
      const signature = customer.signature; 
      if (signature) {
        const signatureHeight = 40; 
        const signatureWidth = 100; 
        doc.addImage(signature, "JPEG", 10, currentY, signatureWidth, signatureHeight);
        currentY += signatureHeight + 10; 
      }
    }
  });

  // Save the PDF
  doc.save("customer_data.pdf");
};
