import React, { useState, useEffect, useRef } from "react";
import {
  Pagination,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
  Popover,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, List } from "@mui/icons-material";

import config from "./Utils/config";
import "../css/SearchForm.css";
import Loader from "./Utils/loader"; // Import Loader component
import ContractStatus from "./ContractStatus";
// Example data (can be replaced with actual search results from API)
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
  const [searchData, setSearchData] = useState({
    firstName: "",
    lastName: "",
    contractNumber: "",
    customerEmail: "",
    customerPhone: "",
    customerId: "",
    eventDate: "",
    advancedSearch: false,
    state: 0,
    city: "",
    branch: "",
    primaryHonoree: "",
    category: 0,
    partyPackage: 0,
    characters: 0,
    bounces: 0,
    addOns: 0,
    venue: 0,
    contractStatusId: 0,
    paymentStatus: 0,
    approval: false, // Added for checkbox
    confirmation: false, // Added for checkbox
    booked: false, // Added for checkbox
    quoted: false, // Added for checkbox
    cancelled: false, // Added for checkbox
    sortOrder: "",
    sortBy: "",
   
  });
  const hasMounted = useRef(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [branches, setBranches] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [packages, setPackages] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [bounces, setBounces] = useState([]);
  const [addons, setAddons] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [venue, setVenues] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0); // To store total number of results
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // Control loader visibility
  const navigate = useNavigate(); // Call useNavigate at the top level
  const [results, setResults] = useState([]); // To store API results

  useEffect(() => {
    const fetchDropdownData = async (url, setter) => {
      const token = localStorage.getItem("token");
      setIsLoading(true);
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDropdownData(config.apiBaseUrl + "States", setStates);
    fetchDropdownData(config.apiBaseUrl + "Categories", setCategories);
    fetchDropdownData(config.apiBaseUrl + "PartyPackages", setPackages);
    fetchDropdownData(config.apiBaseUrl + "Characters", setCharacters);
    fetchDropdownData(config.apiBaseUrl + "Addons", setAddons);
    fetchDropdownData(config.apiBaseUrl + "Bounces", setBounces);
    fetchDropdownData(config.apiBaseUrl + "PaymentStatus", setPaymentStatus);
    fetchDropdownData(config.apiBaseUrl + "Venues", setVenues);
    fetchDropdownData(config.apiBaseUrl + "Branches/BranchList", setBranches);
  }, []);

  const handleFilterChange = (column, value) => {
    // Update searchData state using functional form to ensure using the latest state
    setSearchData((prevState) => ({
      ...prevState,
      [column]: value,
    }));
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchData({
      ...searchData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckboxChange = (e) => {
    setSearchData({ ...searchData, advancedSearch: e.target.checked });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    
    // Update the searchData for approval, confirmation, booked, quoted, and cancelled states
    setSearchData((prevState) => {
      // Check if any of the values is true
      const hasStatus = 
        value.includes("approval") || 
        value.includes("confirmation") || 
        value.includes("booked") || 
        value.includes("quoted") || 
        value.includes("cancelled");
  
      // Update searchData and conditionally set contractStatusId
      return {
        ...prevState,
        approval: value.includes("approval"),
        confirmation: value.includes("confirmation"),
        booked: value.includes("booked"),
        quoted: value.includes("quoted"),
        cancelled: value.includes("cancelled"),
        contractStatusId: hasStatus ? 2 : 0, // Set contractStatusId to 2 if any value is true, else set to 0
      };
    });
  };
  
  // Options for the dropdown (approval and confirmation)
  const options = [
    { label: "Approval", value: "approval" },
    { label: "Confirmation", value: "confirmation" },
    { label: "Booked", value: "booked" },
    { label: "Quoted", value: "quoted" },
    { label: "Cancelled", value: "cancelled" },
  ];
  

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log("Page:" + page);
    fetchSearchResults(page); // Fetch results for the new page
  };

  // Fetch search results from API
  const fetchSearchResults = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    const searchParams = {
      ...searchData,
      page,
      pageSize: rowsPerPage,
    };
    if (searchParams.eventDate === "") searchParams.eventDate = null;
    if (searchParams.customerId === "") searchParams.customerId = 0;
    try {
      const response = await fetch(
        `${config.apiBaseUrl}SearchContract/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(searchParams),
        }
      );

      if (!response.ok) throw new Error("Error fetching search results");
      const data = await response.json();
      setResults(data.data);
   
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };


// Filter results based on searchData
const filteredResults = React.useMemo(() => {
  // Define formatDate function inside useMemo
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle null or undefined date
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
  };

  // Filter results first
  const filtered = results.filter((item) => {
    // Get contractDate from searchData and item, if available
    const filterContractDate = searchData.contractDate ? new Date(searchData.contractDate) : null;
    const itemContractDate = item.contractDate ? new Date(item.contractDate) : null;

    // Get eventDate from searchData and item, if available
    const filterEventDate = searchData.eventDate ? new Date(searchData.eventDate) : null;
    const itemEventDate = item.eventDate ? new Date(item.eventDate) : null;

    // Get createdDate from searchData and item, if available
    const filterCreatedDate = searchData.createdDate ? new Date(searchData.createdDate) : null;
    const itemCreatedDate = item.createdDate ? new Date(item.createdDate) : null;

    // Normalize and filter bounces value
    const normalizedBounces = (searchData.bounces || "").toLowerCase(); // Normalize the search term (case-insensitive)
    const itemBounces = (item.bounces || "").toLowerCase(); // Normalize the item value

    // Normalize and filter addOns value
    const normalizedAddOns = (searchData.addOns || "").toLowerCase(); // Normalize the search term for addOns
    const itemAddOns = (item.addOns || "").toLowerCase(); // Normalize the item value for addOns

    // Normalize and filter characters value
    const normalizedCharacters = (searchData.characters || "").toLowerCase(); // Normalize the search term for characters
    const itemCharacters = (item.characters || "").toLowerCase(); // Normalize the item value for characters

    // Normalize and filter packageName value
    const normalizedPackageName = (searchData.packageName || "").toLowerCase(); // Normalize the search term for packageName
    const itemPackageName = (item.packageName || "").toLowerCase(); // Normalize the item value for packageName

    return (
      (item.firstName == null ||
        (item.firstName || "").toLowerCase().includes((searchData.firstName || "").toLowerCase())) &&
      (item.lastName == null ||
        (item.lastName || "").toLowerCase().includes((searchData.lastName || "").toLowerCase())) &&
      (item.contractNumber == null ||
        (item.contractNumber || "").toLowerCase().includes((searchData.contractNumber || "").toLowerCase())) &&
      (item.emailAddress == null ||
        (item.emailAddress || "").toLowerCase().includes((searchData.emailAddress || "").toLowerCase())) &&
      (item.city == null ||
        (item.city || "").toLowerCase().includes((searchData.city || "").toLowerCase())) &&
      (item.packageName == null ||
        (item.packageName || "").toLowerCase().includes((searchData.packageName || "").toLowerCase())) &&
      (item.stateName == null ||
        (item.stateName || "").toLowerCase().includes((searchData.stateName || "").toLowerCase())) &&
      
      // Check for characters search
      (item.characters == null || itemCharacters.includes(normalizedCharacters)) &&

      // Now handle the bounces search
      (item.bounces == null || itemBounces.includes(normalizedBounces)) && // Normalize and check for partial matching

      // Handle the addOns search
      (item.addOns == null || itemAddOns.includes(normalizedAddOns)) &&

      // Handle the packageName search
      (item.packageName == null || itemPackageName.includes(normalizedPackageName)) &&

      (item.primaryHonoree == null ||
        (item.primaryHonoree || "").toLowerCase().includes((searchData.primaryHonoree || "").toLowerCase())) &&
      
      // Check for contractDate filter
      (filterContractDate === null ||
        itemContractDate?.toISOString().split("T")[0] === filterContractDate?.toISOString().split("T")[0]) &&
      
      // Apply formatDate() for eventDate filtering
      (filterEventDate === null || formatDate(item.eventDate) === formatDate(searchData.eventDate)) &&
      
      // Apply formatDate() for CreatedDate filtering
      (filterCreatedDate === null || formatDate(item.createdDate) === formatDate(searchData.createdDate))
    );
  });

  // Sort the filtered results to bring the items with matching bounces, addOns, characters, or packageName to the top
  const sortedResults = filtered.sort((a, b) => {
    // Normalize values for sorting
    const searchBounces = (searchData.bounces || "").toLowerCase();
    const aBounces = (a.bounces || "").toLowerCase();
    const bBounces = (b.bounces || "").toLowerCase();

    const searchAddOns = (searchData.addOns || "").toLowerCase();
    const aAddOns = (a.addOns || "").toLowerCase();
    const bAddOns = (b.addOns || "").toLowerCase();

    const searchCharacters = (searchData.characters || "").toLowerCase();
    const aCharacters = (a.characters || "").toLowerCase();
    const bCharacters = (b.characters || "").toLowerCase();

    const searchPackageName = (searchData.packageName || "").toLowerCase();
    const aPackageName = (a.packageName || "").toLowerCase();
    const bPackageName = (b.packageName || "").toLowerCase();

    // Check if the bounces field matches the search term
    const aMatchesBounces = aBounces.includes(searchBounces);
    const bMatchesBounces = bBounces.includes(searchBounces);

    // Check if the addOns field matches the search term
    const aMatchesAddOns = aAddOns.includes(searchAddOns);
    const bMatchesAddOns = bAddOns.includes(searchAddOns);

    // Check if the characters field matches the search term
    const aMatchesCharacters = aCharacters.includes(searchCharacters);
    const bMatchesCharacters = bCharacters.includes(searchCharacters);

    // Check if the packageName field matches the search term
    const aMatchesPackageName = aPackageName.includes(searchPackageName);
    const bMatchesPackageName = bPackageName.includes(searchPackageName);

    // Sort the items based on bounces, addOns, characters, and packageName matches
    if (aMatchesBounces && !bMatchesBounces) return -1; // a comes before b
    if (!aMatchesBounces && bMatchesBounces) return 1; // b comes before a

    if (aMatchesAddOns && !bMatchesAddOns) return -1; // a comes before b
    if (!aMatchesAddOns && bMatchesAddOns) return 1; // b comes before a

    if (aMatchesCharacters && !bMatchesCharacters) return -1; // a comes before b
    if (!aMatchesCharacters && bMatchesCharacters) return 1; // b comes before a

    if (aMatchesPackageName && !bMatchesPackageName) return -1; // a comes before b
    if (!aMatchesPackageName && bMatchesPackageName) return 1; // b comes before a

    return 0; // Keep the original order if none match
  });

  return sortedResults;

}, [results, searchData]);

  

  // sorting logic
  const sortedResults = React.useMemo(() => {
    let sortableResults = [...filteredResults];
    if (sortConfig.key !== null) {
      sortableResults.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableResults;
  }, [filteredResults, sortConfig]);

  const paginatedResults = sortedResults.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // function for handling number of rows
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value); // Update rowsPerPage based on selection
    setCurrentPage(1);
  };

  // using for handlerows
  useEffect(() => {
    // Skip fetch on the first render
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    fetchSearchResults(currentPage);
  }, [rowsPerPage, currentPage]);

  //sending parameters
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchSearchResults(); // Fetch results for the first page
  };

  const handleReset = () => {
    setSearchData({
      firstName: "",
      lastName: "",
      contractNumber: "",
      customerEmail: "",
      customerPhone: "",
      eventDate: "",
      sortBy: "",
      sortOrder: "",
      advancedSearch: false,
    });
  };

 


  // const handleEdit = (customerId,contractId)=>{
  //   navigate(/customer/${customerId}/contract/${contractId})
  //   console.log(customerId + contractId);
  //   <navigate
  //   to="/Customer"
  //   state={{
  //     team: [],
  //     timeStart: '12:00 AM',
  //     timeEnd: '12:00 AM',
  //     selectedTeam: 0,
  //     selectedDate: Date
  //   }}
  // />
  
  // };

  

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  

  const handleIconClick = (row, action) => {
    // Set the selectedRow when clicking on an icon button
    setSelectedRow(row);
  
    // Perform the action based on the passed action type
    switch(action) {
      case 'view':
        if (row) {
          navigate(`/customerdetail/${row.customerId}`);
        }
        break;
      case 'edit':
        // Define the edit logic directly here
        if (row && row.customerId && row.contractId) {
         
          // navigate(`/customer/${row.customerId}/contract/${row.contractId}`);
  
          // Optional: navigate with additional state if needed
          navigate(`/customer/${row.customerId}/contract/${row.contractId}`, {
            state: {
              team: [],
              timeStart: '12:00 AM',
              timeEnd: '12:00 AM',
              selectedTeam: 0,
              selectedDate: new Date()
            }
          });
        }
        break;
      case 'delete':
        // Your delete logic here
        console.log("Delete action for delete");
        break;
      default:
        console.log("Unknown action");
    }
  };
  
  
  const formatDate = (dateString) => {
    // Check if the date is null, empty, or an invalid date
    if (!dateString || new Date(dateString) == "Invalid Date") {
      return ""; // Return a blank string if no date is provided or if the date is invalid
    }

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
  };

  // console.log(results);
  // console.log(sortedResults)
  // console.log(searchData);

  return (
    <Box sx={{ p: 4 }} className="search-container">
      <Loader isLoading={isLoading} />

      <h1>Search Form</h1>
      <br></br>
      {/* Search Form */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className="form-grid">
          <Grid item xs={12} md={3}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={searchData.firstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={searchData.lastName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Contract Number"
              variant="outlined"
              fullWidth
              name="contractNumber"
              value={searchData.contractNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Customer Email"
              variant="outlined"
              fullWidth
              name="customerEmail"
              value={searchData.customerEmail}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Customer Phone"
              variant="outlined"
              fullWidth
              name="customerPhone"
              value={searchData.customerPhone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Customer Id"
              variant="outlined"
              fullWidth
              name="customerId"
              value={searchData.customerId}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Event Date"
              variant="outlined"
              fullWidth
              type="date"
              name="eventDate"
              value={searchData.eventDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                multiple
                name="options"
                value={
                  options
                    .filter((option) => searchData[option.value]) // Filter out options that are true in searchData
                    .map((option) => option.value) // Map to get the values that are selected
                }
                onChange={handleSelectChange}
                renderValue={(selected) => selected.join(", ")} // Display selected items
                label="Options"
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={searchData[option.value]} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {branches.length > 0 ? (
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Branch</InputLabel>
                <Select
                  label="Branch"
                  name="branch"
                  value={searchData.branch}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {branches.map((branch) => (
                    <MenuItem key={branch.branchId} value={branch.branchId}>
                      {branch.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          <Grid item xs={12} className="advanced-search">
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchData.advancedSearch}
                  onChange={handleCheckboxChange}
                />
              }
              label="Advanced Search"
            />
          </Grid>

          {/* Advanced Search Fields */}
          {searchData.advancedSearch && (
            <>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>State</InputLabel>
                  <Select
                    value={searchData.state}
                    name="state"
                    onChange={handleInputChange}
                    label="State"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} className="advanced-search-section">
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={searchData.city}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <TextField
                  label="Primary Honoree"
                  variant="outlined"
                  fullWidth
                  name="primaryHonoree"
                  value={searchData.primaryHonoree}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={searchData.category}
                    name="category"
                    onChange={handleInputChange}
                    label="Category"
                  >
                    {categories.map((category) => (
                      <MenuItem
                        key={category.categoryId}
                        value={category.categoryId}
                      >
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Party Package</InputLabel>
                  <Select
                    value={searchData.partyPackage}
                    name="partyPackage"
                    onChange={handleInputChange}
                    label="Party Package"
                  >
                    {packages.map((pkg) => (
                      <MenuItem
                        key={pkg.partyPackageId}
                        value={pkg.partyPackageId}
                      >
                        {pkg.partyPackageName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Characters</InputLabel>
                  <Select
                    value={searchData.characters}
                    name="characters"
                    onChange={handleInputChange}
                    label="Characters"
                  >
                    {characters.map((character) => (
                      <MenuItem
                        key={character.characterId}
                        value={character.characterId}
                      >
                        {character.characterName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Bounces</InputLabel>
                  <Select
                    value={searchData.bounces}
                    name="bounces"
                    onChange={handleInputChange}
                    label="Bounces"
                  >
                    {bounces.map((bounce) => (
                      <MenuItem key={bounce.bounceId} value={bounce.bounceId}>
                        {bounce.bounceName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Add-ons</InputLabel>
                  <Select
                    value={searchData.addOns}
                    name="addOns"
                    onChange={handleInputChange}
                    label="Add-ons"
                  >
                    {addons.map((addon) => (
                      <MenuItem key={addon.addonId} value={addon.addonId}>
                        {addon.addonName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Venus</InputLabel>
                  <Select
                    value={searchData.venue}
                    name="venue"
                    onChange={handleInputChange}
                    label="Venue"
                  >
                    {venue.map((venue, index) => (
                      <MenuItem key={venue.venueId} value={venue.venueId}>
                        {venue.venueName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="advanced-search-section">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Payment Status</InputLabel>
                  <Select
                    value={searchData.paymentStatus}
                    name="paymentStatus"
                    onChange={handleInputChange}
                    label="Payment Status"
                  >
                    {paymentStatus.map((status, index) => (
                      <MenuItem
                        key={status.paymentStatusId}
                        value={status.paymentStatusId}
                      >
                        {status.paymentStatusName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mr: 2 }}
          >
            Search
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </form>

      {/* Displaying Results */}

      {results.length > 0 && (
        <>
          <ContractStatus />
          <Box sx={{ mt: 4 }}>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <h2>Search Results</h2>
              <FormControl>
                <InputLabel id="rows-per-page-label">
                  Select Number of Rows
                </InputLabel>
                <Select
                  labelId="rows-per-page-label"
                  value={rowsPerPage}
                  style={{ width: "150px", height: "40px" }}
                  label="Select Number of Rows"
                  onChange={handleRowsPerPageChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TableContainer component={Paper}>
              <Table>
                <TableHead className="result-table">
                  <TableRow>
                    <TableCell onClick={() => requestSort("firstName")}>
                      First Name
                      {sortConfig.key === "firstName" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("lastName")}>
                      Last Name
                      {sortConfig.key === "lastName" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("contractNumber")}>
                      Contract Number
                      {sortConfig.key === "contractNumber" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("contractDate")}>
                      Contract Date
                      {sortConfig.key === "contractDate" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("eventDate")}>
                      Event Date
                      {sortConfig.key === "eventDate" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>

                    <TableCell onClick={() => requestSort("eventDate")}>
                      Created On
                      {sortConfig.key === "eventDate" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>

                    <TableCell onClick={() => requestSort("emailAddress")}>
                      Email
                      {sortConfig.key === "emailAddress" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("stateName")}>
                      State
                      {sortConfig.key === "stateName" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("city")}>
                      City
                      {sortConfig.key === "city" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("packageName")}>
                      Party Package
                      {sortConfig.key === "packageName" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward  
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("approval")}>
                      Approval
                      {sortConfig.key === "approval" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("confirmation")}>
                      Confirmation
                      {sortConfig.key === "confirmation" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("primaryHonoree")}>
                      Primary Honoree
                      {sortConfig.key === "primaryHonoree" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("characters")}>
                      Characters
                      {sortConfig.key === "characters" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("bounces")}>
                      Bounces
                      {sortConfig.key === "bounces" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell onClick={() => requestSort("addOns")}>
                      Add-ons
                      {sortConfig.key === "addOns" &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          <ArrowDownward
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ))}
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.firstName || ""}
                        onChange={(e) =>
                          handleFilterChange("firstName", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.lastName || ""}
                        onChange={(e) =>
                          handleFilterChange("lastName", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.contractNumber || ""}
                        onChange={(e) =>
                          handleFilterChange("contractNumber", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="date"
                        value={searchData.contractDate || ""}
                        onChange={(e) =>
                          handleFilterChange("contractDate", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="date"
                        value={searchData.eventDate || ""}
                        onChange={(e) =>
                          handleFilterChange("eventDate", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="date"
                        value={searchData.createdDate || ""}
                        onChange={(e) =>
                          handleFilterChange("createdDate", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.emailAddress || ""}
                        onChange={(e) =>
                          handleFilterChange("emailAddress", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <FormControl style={{ minWidth: "150px" }}>
                        <Select
                          style={{ height: "35px" }}
                          value={searchData.stateName || ""}
                          onChange={(e) =>
                            handleFilterChange("stateName", e.target.value)
                          }
                          label="State"
                        >
                          {states.map((state) => (
                            <MenuItem
                              key={state.stateId}
                              value={state.stateName}
                            >
                              {state.stateName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.city || ""}
                        onChange={(e) =>
                          handleFilterChange("city", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      <FormControl style={{ minWidth: "150px" }}>
                        <Select
                          style={{ height: "35px" }}
                          value={searchData.packageName || ""}
                          onChange={(e) =>
                            handleFilterChange("packageName", e.target.value)
                          }
                          label="Package Name"
                        >
                          {packages.map((pkg) => (
                            <MenuItem
                              key={pkg.partyPackageId}
                              value={pkg.partyPackageName}
                            >
                              {pkg.partyPackageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell className="table-filter-cell"></TableCell>
                    <TableCell className="table-filter-cell"></TableCell>
                    <TableCell className="table-filter-cell">
                      <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.primaryHonoree || ""}
                        onChange={(e) =>
                          handleFilterChange("primaryHonoree", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                    <input
                        className="table-filter-input"
                        type="text"
                        name="characters"
                        value={searchData.characters || ""}
                        onChange={(e) =>
                          handleFilterChange("characters", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                    <input
                        className="table-filter-input"
                        type="text"
                        name="bounces"
                        value={searchData.bounces || ""}
                        onChange={(e) =>
                          handleFilterChange("bounces", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                    <input
                        className="table-filter-input"
                        type="text"
                        value={searchData.addOns || ""}
                        onChange={(e) =>
                          handleFilterChange("addOns", e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell className="table-filter-cell">
                      {/* Empty space for action button column */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedResults.map((row) => {
                    let backgroundColor = "";
                    let color = "black";

                    switch (row.contractStatusId) {
                      case 1:
                        backgroundColor = "green";
                        color = "white";
                        break;
                      case 2:
                        backgroundColor = "lightblue";
                        break;
                      case 3:
                        backgroundColor = "white";
                        break;
                      case 4:
                        backgroundColor = "yellow";
                        break;
                      case 5:
                        backgroundColor = "red";
                        color = "white";
                        break;
                      default:
                        backgroundColor = ""; // Default background color if ContractStatusId doesn't match
                    }

                    return (
                      <TableRow
                        key={row.userId}
                        sx={{
                          backgroundColor: backgroundColor,
                          color: color,
                          fontWeight: "bold",
                          cursor: "pointer", // Add cursor pointer to indicate clickability
                          "&:hover": {
                            backgroundColor: "lightgray",
                          },
                        }}
                      >
                        <TableCell sx={{ color: color }}>
                          {row.firstName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.lastName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.contractNumber}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {formatDate(row.contractDate)}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {formatDate(row.eventDate)}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {formatDate(row.createdDate)}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.emailAddress}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.stateName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>{row.city}</TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.packageName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.approval ? "Yes" : "No"}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.confirmation ? "Yes" : "No"}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.primaryHonoree}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.charactersName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.bouncesName}
                        </TableCell>
                        <TableCell sx={{ color: color }}>
                          {row.addOnsName}
                        </TableCell>
                        <TableCell style={{display:'flex', gap:'5px', height:'fiContent', alignItems:'center', padding:'20px'}}>
                          <button
                  className="action-button edit-button"
                  onClick={() => handleIconClick(row, 'view')}
                  title="View Contract"
                >
                  <i className="fa fa-eye"></i>
                </button>
                <button
                  className="action-button edit-button"
                  onClick={() => handleIconClick(row, 'edit')} 
                  title="Edit Contract"
                >
                  <i className="fa fa-edit"></i>
                </button>
                <button
                  className="action-button delete-button"
                  onClick={() => handleIconClick(row, 'delete')}
                  title="Cancel Contract"
                >
                  <i className="fa fa-trash"></i>
                </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            

            {/* Pagination */}

            <Stack spacing={2} style={{ marginTop: "20px" }}>
              <Pagination
                className="pagination"
                count={Math.ceil(totalCount / rowsPerPage)} // Total pages
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </Box>
        </>
      )}

      {!results.length && (
        <>
          <ContractStatus />

          <Typography>No results found</Typography>
        </>
      )}
    </Box>
  );
};

export default SearchForm;
