import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  TextField,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import { useNavigate } from "react-router-dom";

function CustomerTable() {
  const [searchData, setSearchData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNo: "",
    customerId: "",
    city: "",
    state: "",
  });
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [formSearchData, setFormSearchData] = useState([]);
  const [isSearchedData, setIsSearchedData] = useState(false); // Flag to track whether filtering is applied
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [states, setStates] = useState([]);
  const [page, setPage] = useState(1);
  const [contractData, setContractData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "contractCount", // Default key for sorting
    direction: "asc",
  });
  const [searchQuery, setSearchQuery] = useState({
    contractCount: "",
    customerId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNo: "",
    address: "",
    city: "",
    stateId: "",
    zip: "",
  });

  const [expandedRows, setExpandedRows] = useState({});


  useEffect(() => {
    const fetchDropdownData = async (url, setter) => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      } finally {
      }
    };

    fetchDropdownData(config.apiBaseUrl + "States", setStates);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}NewContract/GetNewCustomer`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const userData = await response.json();
        setData(userData.customerWithContracts); // Set the data for the table
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);
console.log(data)
  const sortData = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Function to handle sorting of columns
  const handleSort = (columnKey) => {
    const keyMapping = {
      "Total Contracts": "contractCount",
      "Customer Id": "customerId",
      "First Name": "firstName",
      "Last Name": "lastName",
      Email: "emailAddress",
      "Phone No": "phoneNo",
      Address: "address",
      City: "city",
      Zip: "zip",
    };

    const newDirection =
      sortConfig.key === keyMapping[columnKey] && sortConfig.direction === "asc"
        ? "desc"
        : "asc";

    setSortConfig({
      key: keyMapping[columnKey],
      direction: newDirection,
    });
  };

  const handleSearchChange = (columnKey, value) => {
    setSearchQuery({ ...searchQuery, [columnKey]: value });
  };

  const filterData = (data) => {
    return data.filter((customer) => {
      return Object.keys(searchQuery).every((key) => {
        const value = handleEmptyData(customer[key]);
        return value
          .toString()
          .toLowerCase()
          .includes(searchQuery[key].toLowerCase());
      });
    });
  };

  const handleEmptyData = (value) => {
    return value === null || value === undefined || value === ""
      ? "N/A"
      : value;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleExpandRow = (customerId) => {
    setExpandedRows((prevExpandedRows) => {
      const isExpanded = prevExpandedRows[customerId];
      const newExpandedRows = {
        ...prevExpandedRows,
        [customerId]: !isExpanded,
      };

      // Fetch contract data if expanding
      if (!isExpanded) {
        fetchContractsData(customerId);
      }

      return newExpandedRows;
    });
  };

  const fetchContractsData = async (customerId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${config.apiBaseUrl}NewContract/GetContractByCustomerId/${customerId}`, // Corrected URL interpolation
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const contracts = await response.json();
        setContractData((prevData) => ({
          ...prevData,
          [customerId]: contracts.contractList, // Store the contract data by customerId
        }));
      } else {
        throw new Error("Failed to fetch contracts data");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewCustomer = (customerId) => {
    navigate(`/customerdetail/${customerId}`);
  };

  const handleEdit = (customerId, contractId) => {
    // Navigate to the customer page with customerId and contractId in the URL
    navigate(`/customer/${customerId}/contract/${contractId}`);
  };

  function convertToNormalDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  }

  if (loading) {
    return <Loader isLoading={loading} />;
  }
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // Handle input change for dynamic form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission (filter the data based on the form fields)
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page reload)

    // Filter the data based on the values in searchData
    const filtered = data.filter((item) => {
      return Object.keys(searchData).every((key) => {
        // If a search field is empty, it should not filter by that field
        if (searchData[key] === "") {
          return true;
        }
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchData[key].toLowerCase());
      });
    });

    setFormSearchData(filtered);
    setIsSearchedData(true);
  };

  const resetSearchedData = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNo: "",
    customerId: "",
    city: "",
    state: "",
  };

  const handleReset = () => {
    setSearchData(resetSearchedData);
    setIsSearchedData(false);
  };


  console.log(formSearchData)
  // data to display
  const dataToDisplay = isSearchedData ? formSearchData : data;

  //  Sort the entire data before applying pagination
  const sortedData = sortData(
    dataToDisplay,
    sortConfig.key,
    sortConfig.direction
  );

  // Calculate total pages for pagination
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  //pagination to the sorted data
  const paginatedData = sortedData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Filter only the visible (paginated) data
  const filteredData = paginatedData ? filterData(paginatedData) : [];

console.log(contractData)
  return (
    <div>
      <div style={{ width: "90%", margin: "50px auto" }}>
        <h2 style={{ textAlign: "center" }}>Search Customer</h2>

        <form style={{ marginTop: "30px" }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="firstName"
                value={searchData.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="lastName"
                value={searchData.lastName}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Email"
                variant="outlined"
                fullWidth
                name="emailAddress"
                value={searchData.emailAddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Phone"
                variant="outlined"
                fullWidth
                name="phoneNo"
                value={searchData.phoneNo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Id"
                variant="outlined"
                fullWidth
                name="customerId"
                value={searchData.customerId}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={searchData.city}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>State</InputLabel>
                  <Select
                    value={searchData.state}
                    name="state"
                    onChange={handleInputChange}
                    label="State"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            {/* Add this Grid item to place the Button on the next line */}
            <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ width: "auto" }}
                >
                  Search
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="reset"
                  onClick={handleReset}
                  fullWidth
                  sx={{ width: "auto" }}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>

      <Typography
        variant="h5"
        style={{ textAlign: "center", margin: "30px auto" }}
      >
        Customer Details
      </Typography>

      {data && data.length > 0 ? (
        <div style={{ margin: "20px auto", width: "90%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Total Contracts",
                    "Customer Id",
                    "First Name",
                    "Last Name",
                    "Email",
                    "Phone No",
                    "Address",
                    "City",
                    "Zip",
                    "View",
                  ].map((columnKey) => (
                    <TableCell
                      key={columnKey}
                      onClick={() => handleSort(columnKey)}
                      style={{ cursor: "pointer" }}
                    >
                      {columnKey}
                      {sortConfig.key ===
                        (columnKey === "Total Contracts"
                          ? "contractCount"
                          : columnKey) &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowUpward fontSize="small" />
                        ) : (
                          <ArrowDownward fontSize="small" />
                        ))}
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  {[
                    "contractCount",
                    "customerId",
                    "firstName",
                    "lastName",
                    "emailAddress",
                    "phoneNo",
                    "address",
                    "city",
                    "zip",
                  ].map((columnKey) => (
                    <TableCell
                      key={columnKey}
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={searchQuery[columnKey]}
                        onChange={(e) =>
                          handleSearchChange(columnKey, e.target.value)
                        }
                        placeholder="Search"
                        style={{ width: "100%", backgroundColor: "#f9f9f9" }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredData.map((customer) => (
                  <React.Fragment key={customer.customerId}>
                    <TableRow key={`customer-${customer.customerId}`}>
                      <TableCell>
                        {customer.contractCount > 0 ? (
                          <>
                            {expandedRows[customer.customerId] ? (
                              <KeyboardArrowDown
                                fontSize="small"
                                onClick={() =>
                                  handleExpandRow(customer.customerId)
                                }
                                style={{
                                  cursor: "pointer",
                                  marginTop: "-5px",
                                }}
                              />
                            ) : (
                              <KeyboardArrowRight
                                fontSize="small"
                                onClick={() =>
                                  handleExpandRow(customer.customerId)
                                }
                                style={{
                                  cursor: "pointer",
                                  marginTop: "-5px",
                                }}
                              />
                            )}
                            <span style={{ marginLeft: "10px" }}>
                              {handleEmptyData(customer.contractCount)}
                            </span>
                          </>
                        ) : (
                          <span style={{ marginLeft: "30px" }}>
                            {handleEmptyData(customer.contractCount)}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {handleEmptyData(customer.customerId)}
                      </TableCell>
                      <TableCell>
                        {handleEmptyData(customer.firstName)}
                      </TableCell>
                      <TableCell>
                        {handleEmptyData(customer.lastName)}
                      </TableCell>
                      <TableCell>
                        {handleEmptyData(customer.emailAddress)}
                      </TableCell>
                      <TableCell>{handleEmptyData(customer.phoneNo)}</TableCell>
                      <TableCell>{handleEmptyData(customer.address)}</TableCell>
                      <TableCell>{handleEmptyData(customer.city)}</TableCell>
                      <TableCell>{handleEmptyData(customer.zip)}</TableCell>
                      <TableCell>
                        <button
                          className="action-button edit-button"
                          title="View Customer"
                          onClick={() =>
                            handleViewCustomer(customer.customerId)
                          }
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                      </TableCell>
                    </TableRow>

                    {expandedRows[customer.customerId] && (
                      <TableRow key={`expandedRow-${customer.customerId}`}>
                        <TableCell colSpan={10}>
                          <TableContainer
                            style={{ margin: "0" }}
                            component={Paper}
                          >
                            <Table style={{ margin: "0" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Contract Number</TableCell>
                                  <TableCell>Created Date</TableCell>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Team No</TableCell>
                                  <TableCell>Time Slot</TableCell>
                                  <TableCell>Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {contractData[customer.customerId]?.map(
                                  (contract) => (
                                    <TableRow
                                      key={contract.contractTimeTeamInfoId}
                                    >
                                      <TableCell>
                                        {contract.contractNo}
                                      </TableCell>
                                      <TableCell>
                                        {convertToNormalDate(
                                          contract.createdDate
                                        )}
                                      </TableCell>
                                      <TableCell>{contract.date}</TableCell>
                                      <TableCell>{contract.team.teamNo}</TableCell>
                                      <TableCell>{contract.timeSlot.time}</TableCell>
                                      <TableCell>
                                        <button
                                          className="action-button edit-button"
                                          onClick={() =>
                                            handleEdit(
                                              customer.customerId,
                                              contract.contractId
                                            )
                                          }
                                          title="Edit Contract"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={totalPages} // Set total pages based on the sorted data
            page={page} // Current page
            onChange={handleChangePage} // Handler for page change
            showFirstButton // Optionally show first button
            showLastButton // Optionally show last button
            sx={{ marginTop: 2, display: "flex", justifyContent: "center" }} // Optional styling
          />
        </div>
      ) : (
        <Typography>No customers available.</Typography>
      )}
    </div>
  );
}

export default CustomerTable;
