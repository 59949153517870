import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "../Utils/config";
import { ApiDataContext } from "./ApiDataContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function UserTotalContract() {
  const { barToday } = useContext(ApiDataContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState(null);
  const [weekStartDate, setWeekStartDate] = useState(new Date());
  const [weekEndDate, setWeekEndDate] = useState(new Date());
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(new Date());

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}Users/getCurrentUser-Details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data);
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();

    // Initialize the start date for current week
    const today = new Date();
    const { startDate: currentStartDate } = calculateWeekDates(today);
    setCurrentWeekStartDate(currentStartDate);

    // Set the current week's dates (not the previous week)
    const { startDate, endDate } = calculateWeekDates(today);
    setWeekStartDate(startDate);
    setWeekEndDate(endDate);
  }, []);

  console.log("Data for total", barToday);

  // Utility function to calculate the start and end of the week
  const calculateWeekDates = (date) => {
    const dayOfWeek = date.getDay();
    const startDate = new Date(date);
    const endDate = new Date(date);

    // Set start date to the previous Sunday or Monday
    startDate.setDate(date.getDate() - dayOfWeek + 1); // For Monday (Adjust as needed)
    endDate.setDate(startDate.getDate() + 6); // End date is 6 days after start

    return { startDate, endDate };
  };

  // Function to change the week
  const changeWeek = (direction) => {
    const newStartDate = new Date(weekStartDate);
    if (direction === "next") {
      newStartDate.setDate(weekStartDate.getDate() + 7); // Next week
    } else if (direction === "prev") {
      newStartDate.setDate(weekStartDate.getDate() - 7); // Previous week
    }

    const { startDate, endDate } = calculateWeekDates(newStartDate);
    setWeekStartDate(startDate);
    setWeekEndDate(endDate);
  };

  // Function to get day of the week (e.g., Sunday, Monday)
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };

  // Filter the contracts to only include those from the current week
  const getContractsByDay = () => {
    if (!barToday || barToday.length === 0) return [];

    const contractCountByDay = barToday.reduce((acc, contract) => {
      const date = new Date(contract.date);
      if (date >= weekStartDate && date <= weekEndDate) {
        const dayOfWeek = getDayOfWeek(date);
        if (acc[dayOfWeek]) {
          acc[dayOfWeek] += 1;
        } else {
          acc[dayOfWeek] = 1;
        }
      }
      return acc;
    }, {});

    return contractCountByDay;
  };

  const contractCountByDay = getContractsByDay();

  // Calculate the total number of contracts for the current week
  const totalContracts = Object.values(contractCountByDay).reduce(
    (sum, count) => sum + count,
    0
  );

  // Chart data for the current week
  const chartData = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ], // Days of the week
    datasets: [
      {
        label: "Contracts by Day (This Week)",
        data: [
          contractCountByDay["Sunday"] || 0,
          contractCountByDay["Monday"] || 0,
          contractCountByDay["Tuesday"] || 0,
          contractCountByDay["Wednesday"] || 0,
          contractCountByDay["Thursday"] || 0,
          contractCountByDay["Friday"] || 0,
          contractCountByDay["Saturday"] || 0,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Check if the current week is the same as the week we're displaying
  const isCurrentWeek =
    weekStartDate.getTime() === currentWeekStartDate.getTime();

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <h3>Total Contracts This Week : {totalContracts}</h3>

      <div style={{ textAlign: "center", color: "gray" }}>
        <strong>
          Week Dates: {weekStartDate.toLocaleDateString()} -{" "}
          {weekEndDate.toLocaleDateString()}
        </strong>
      </div>

      {/* Week Navigation Buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <button
          onClick={() => changeWeek("prev")}
          style={{ backgroundColor: "#007bff" }}
        >
          Previous Week
        </button>
        <button
          onClick={() => changeWeek("next")}
          disabled={isCurrentWeek} // Disable "Next Week" button if it's the current week
          style={{
            backgroundColor: isCurrentWeek ? "#d3d3d3" : "#007bff",
            color: isCurrentWeek ? "#888" : "#fff",
            cursor: isCurrentWeek ? "not-allowed" : "pointer",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            transition: "background-color 0.3s ease",
          }}
        >
          Next Week
        </button>
      </div>

      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

export default UserTotalContract;
