import { useState } from "react";
import {
    TextField, Grid, Button
} from '@mui/material';

const BranchFilters = ({ onSearchDataChange }) => {
    const [searchData, setSearchData] = useState({
        branchName: "",
        email: "",
        phone: "",
        contactPerson: "",
        createdDateFrom: "",
        createdDateTo: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const newSearchData = { ...searchData, [name]: value };
        setSearchData(newSearchData);
    };

    const handleSearch = () => {
        // Trigger the search only when the Search button is clicked
        onSearchDataChange(searchData);
    };

    const handleReset = () => {
        // Reset the search data
        const resetData = {
            branchName: "",
            email: "",
            phone: "",
            contactPerson: "",
            createdDateFrom: "",
            createdDateTo: ""
        };
        setSearchData(resetData);
        onSearchDataChange(resetData); // Reset the search data in the parent as well
    };

    return (
        <div className="form-container">
            <Grid container spacing={2} className="form-grid">
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        name="branchName"
                        value={searchData.branchName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={searchData.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Phone"
                        variant="outlined"
                        fullWidth
                        name="phone"
                        value={searchData.phone}
                        onChange={handleInputChange}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Contact Person"
                        variant="outlined"
                        fullWidth
                        name="contactPerson"
                        value={searchData.contactPerson}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        label="Date From"
                        variant="outlined"
                        fullWidth
                        name="createdDateFrom"
                        value={searchData.createdDateFrom}
                        onChange={handleInputChange}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Date To"
                        variant="outlined"
                        fullWidth
                        name="createdDateTo"
                        value={searchData.createdDateTo}
                        onChange={handleInputChange}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>

            {/* Add Search and Reset Buttons */}
            <Grid container spacing={2}  style={{ marginTop: '0px' }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default BranchFilters;
