import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { AccountCircle, ExpandMore } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import config from "./Utils/config";
import Logo from "../Images/bg.jpg"; // Import your logo
import Loader from "./Utils/loader";
import { PrintCustomerDetails } from "./Utils/PrintFeature";
import showToast from "./Utils/showToast";
import "../css/ViewCustomer.css";

function ViewCustomerPublic() {
  const [data, setData] = useState({}); // Initialize with null instead of an empty array

  const [error, setError] = useState(null);
  const [openPanels, setOpenPanels] = useState(["panel1"]);
  const[loading, setLoading] = useState(true);
  const { customerId } = useParams();
  const [userToken, setUserToken] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [openModal, setOpenModal] = useState(true); // Single modal for both verification
  const [signature, setSignature] = useState(null);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(false);
  const handlePrint = () => {
    PrintCustomerDetails(data);
    showToast({
      type: "success",
      message: "PDF generated successfully!",
    });
  };

  const handleAccordionChange = (panel) => {
    setOpenPanels((prev) =>
      prev.includes(panel) ? prev.filter((p) => p !== panel) : [...prev, panel]
    );
  };

  const getAccordionStyles = (panel) => {
    const isSelected = openPanels.includes(panel);
    return {
      background: isSelected
        ? "linear-gradient(to top, #4481eb 0%, #04befe 100%)"
        : "#f1f1f1",
      color: isSelected ? "#fff" : "#000",
    };
  };

  const renderAccordionContent = (panel) => {
    switch (panel) {
      case "panel1":
        return (
          <>
            <p>
              <b> Name : </b>
              {data[0]?.firstName} {data[0]?.lastName}
            </p>
            <p>
              <b> Address : </b> {data[0]?.address}
            </p>
            <p>
              <b> Phone : </b>
              {data[0]?.phoneNo}
            </p>
            <p>
              <b> Email : </b>
              {data[0]?.emailAddress}
            </p>
          </>
        );
      case "panel2":
        return (
          <>
            <p>
              <b> Event Date : </b>
              {data[0]?.eventDate}
            </p>
            <p>
              <b> Event Time : </b>
              {data[0]?.timeSlot}
            </p>
            <p>
              <b> Event Venue : </b>
              {data[0]?.eventInfoVenue}
            </p>
          </>
        );
      case "panel3":
        return (
          <>
            <p>
              <b> Package Name : </b>
              {data[0]?.packageName}
            </p>
            <p>
              <b> CharacterName : </b>
              {data[0]?.characterName}
            </p>
            <p>
              <b> Addons : </b>
              {data[0]?.addons}
            </p>
            <p>
              <b> Bounces : </b>
              {data[0]?.bounces}
            </p>
          </>
        );

      default:
        return null;
    }
  };

  const handleFirstModalSubmit = async () => {
    if (!userToken) {
      setError("Please enter a verification code.");
      return;
    }

    if (!customerId) {
      setError("Customer ID is missing.");
      return;
    }
   
    try {
      // Constructing the GET URL with query parameters
      const response = await fetch(
        `${config.apiBaseUrl}SearchContract/Details?customerId=${customerId}&token=${userToken}`
      );

      // Check if the response status is OK (200-299)
      if (response.status >= 200 && response.status < 300) {
        const result = await response.json();
        setIsVerified(true); // Set verification state to true
        setData(result); // Store the response data
        const signatureFromData = result[0]?.signature;
        if (signatureFromData && signatureFromData !== "") {
          setSignature(signatureFromData);
          setIsCanvasDisabled(true);
        }
        setOpenModal(false); // Close the modal
      } else {
        // Handle non-200 status codes
        const result = await response.json();
        setError(
          result?.message || `Error: ${response.status} - Something went wrong`
        );
      }
    } catch (error) {
      console.error("Verification Error:", error); // Log the full error for debugging
      setError("Invalid Token");
    }
   
  };

  // Drawing functions
  const startDrawing = (e) => {
    if (isCanvasDisabled) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // Get the position of the canvas relative to the page
    const rect = canvas.getBoundingClientRect();
    // Adjust the starting point of the drawing
    ctx.beginPath();
    ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top);
    setIsDrawing(true);
  };

  let penWidth = 2; // Initial pen width

  // Draw function
  const draw = (e) => {
    if (isCanvasDisabled) return;
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineWidth = penWidth;
    // Get the position of the canvas relative to the page
    const rect = canvas.getBoundingClientRect();
    // Draw the line using the adjusted coordinates
    ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (isCanvasDisabled) return;
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    if (isCanvasDisabled) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clears the canvas
  };

  // Submit signature
  const handleSubmit = async () => {
    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d"); // Get canvas context
      const signature = canvas.toDataURL();

      // Check if the canvas is empty by checking pixel data (non-white pixels)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const canvasPixels = imageData.data;

      let isCanvasEmpty = true;
      for (let i = 0; i < canvasPixels.length; i += 4) {
        const alpha = canvasPixels[i + 3]; // alpha channel (opacity) - if it's > 0, the pixel is drawn
        if (alpha !== 0) {
          isCanvasEmpty = false;
          break;
        }
      }

      if (isCanvasEmpty) {
        alert("Please provide a signature before submitting.");
        return;
      }

      const formData = data[0];
      const customerId = parseInt(formData?.customerId, 10);
      const contractStatusId = 2;

      if (!customerId || !contractStatusId) {
        alert("Missing required data.");
        return;
      }

      const payload = {
        customerId: customerId,
        contractStatusId: contractStatusId,
        signature: signature,
      };

      const token = localStorage.getItem("token");

      const apiUrl = `${config.apiBaseUrl}UpdateContractStatusPublic`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // Only log success message if everything is fine
      if (response.status === 200) {
        console.log("Signature submitted successfully.");
        setSignature(signature);
        showToast({
          type: "success",
          message: "Signature Submitted successfully!",
        });
        clearCanvas();
      } else {
        console.error(`Failed to submit signature. Status: ${response.status}`);
        alert("No contracts found for the specified customer ID");
      }
    } catch (error) {
      console.error("Error submitting signature:", error);
    }
  };

  
  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#2196F3", width: "100%" }}
      >
        <Toolbar>
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "40px", marginRight: "10px" }}
          />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Clowns
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit">
              <AccountCircle sx={{ mr: 1 }} />
            </IconButton>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Verification Modal */}
      <Dialog
        open={openModal}
        disableBackdropClick // Disables backdrop click closing
        disableEscapeKeyDown // Disables escape key closing
      >
        <DialogTitle>Verification Code</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Verification Code"
            fullWidth
            value={userToken}
            onChange={(e) => setUserToken(e.target.value)}
          />
          {error && <div style={{ color: "red" }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFirstModalSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
{/* Conditional Rendering: Only render this when the dialog is closed */}

{!openModal && (
  <div className="view-customer-container">
    <h1>Customer Details</h1>

    <div className="custom-accordion-container">
      <div className="custom-accordion-container-btn">
        <Button onClick={handlePrint} variant="contained" color="primary">
          Print
        </Button>
      </div>
      {["panel1", "panel2", "panel3"].map((panel) => (
        <Accordion
          key={panel}
          expanded={openPanels.includes(panel)}
          onChange={() => handleAccordionChange(panel)}
          className="custom-accordion-heading"
        >
          <AccordionSummary
            style={getAccordionStyles(panel)}
            expandIcon={
              <ExpandMore
                style={{
                  color: openPanels.includes(panel) ? "#fff" : "#000",
                }}
              />
            }
            aria-controls={`${panel}-content`}
            id={`${panel}-header`}
          >
            {panel === "panel1" && "Personal Details"}
            {panel === "panel2" && "Event Information"}
            {panel === "panel3" && "Package Details"}
          </AccordionSummary>
          <AccordionDetails className="custom-accordion-para">
            {renderAccordionContent(panel)}
          </AccordionDetails>
        </Accordion>
      ))}

      <div>
        <div className="sign-container">
          <div className="canva-container">
            {signature ? (
              <>
                <h3 style={{ textAlign: "center" }}>Your Sign</h3>
                <div>
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                      src={signature}
                      alt="Signature"
                      width={200}
                      height={100}
                    />
                  </div>
                </div>
              </>
            ) : (
              // If signature doesn't exist, show canvas and buttons to allow drawing
              <>
                <h3>Sign Below</h3>
                <div>
                  <canvas
                    ref={canvasRef}
                    width={400}
                    height={200}
                    style={{
                      border: "1px solid black",
                      display: "flex",
                      margin: "10px auto",
                      pointerEvents: isCanvasDisabled ? "none" : "auto", // Disable canvas interaction if needed
                    }}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                  ></canvas>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      onClick={clearCanvas}
                      variant="contained"
                      sx={{ height: "100%", marginBottom: "10px" }}
                      color="primary"
                      disabled={isCanvasDisabled}
                    >
                      Clear
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ height: "100%", marginBottom: "10px" }}
                      color="primary"
                      disabled={isCanvasDisabled}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

    </>
  );
}

export default ViewCustomerPublic;
