import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "../Utils/config";

// Register necessary components of Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ContractGraph({ setAllCount }) {
  const [data, setData] = useState([]); // To store fetched contract data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [selectedStatus, setSelectedStatus] = useState("All"); // Default to "All"
  const [chartData, setChartData] = useState(null); // For chart data

  // Full week array (always includes all days)
  const fullWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Fetch contract data when component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchContractData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}MainDashboard/WeeklyContract`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const fetchedData = await response.json();
        console.log("Fetched Data (raw):", fetchedData); // Logs the raw fetched data

        setData(fetchedData);
        updateChartData(fetchedData, selectedStatus); // Initialize chart with default status
        calculateAllCount(fetchedData); // Calculate and store the sum of all counts
      } catch (error) {
        setError("Error fetching contract data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContractData();
  }, []); // Only run on mount

  // Function to calculate the sum of all counts
  const calculateAllCount = (data) => {
    const totalCount = data.reduce((acc, item) => acc + item.count, 0); // Sum up all counts
    setAllCount(totalCount); // Set the sum to the state variable
  };

  // Update chart data based on selected status
  const updateChartData = (data, status) => {
    let filteredData = [];
    if (status === "All") {
      // If "All" is selected, aggregate counts for the same dayOfWeek
      const aggregatedData = data.reduce((acc, item) => {
        const existing = acc.find(
          (entry) => entry.dayOfWeek === item.dayOfWeek
        );
        if (existing) {
          existing.count += item.count; // Sum the counts
        } else {
          acc.push({ dayOfWeek: item.dayOfWeek, count: item.count });
        }
        return acc;
      }, []);

      filteredData = aggregatedData; // Use aggregated data
    } else {
      filteredData = data.filter((item) => item.statusName === status); // Otherwise, filter by selected status
    }

    // Prepare the chart data
    const chartLabels = fullWeek; // Use full week as labels
    const chartValues = fullWeek.map((day) => {
      const dayData = filteredData.find((item) => item.dayOfWeek === day);
      return dayData ? dayData.count : 0; // If data for that day exists, use the count, otherwise use 0
    });

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: `${status === "All" ? "All Statuses" : status} Contracts`,
          data: chartValues,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  // Handle status change from dropdown
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Update the selected status
    updateChartData(data, e.target.value); // Update the chart with new status or "All"
  };

  // Extract unique status names from the data
  const uniqueStatuses = [...new Set(data.map((item) => item.statusName))];

  return (
    <div>
      <h3> Last Week Data By Status</h3>
      {/* Dropdown to select status */}
      <select onChange={handleStatusChange} value={selectedStatus}>
        <option value="All">All</option>
        {uniqueStatuses.length > 0 ? (
          uniqueStatuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))
        ) : (
          <option>No status available</option>
        )}
      </select>

      {/* Bar Chart */}
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          paddingTop: "20px",
          height: "400px",
        }}
      >
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {chartData ? (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                  },
                },
                y: {
                  beginAtZero: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        ) : (
          <p>No data available for the selected status.</p>
        )}
      </div>
    </div>
  );
}

export default ContractGraph;
