import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!token) {
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/" />; // Redirect to home if not authorized
  }

  // Pass the role to the children as a render prop
  return typeof children === 'function' ? children({ role: userRole }) : children;
};

export default ProtectedRoute;
