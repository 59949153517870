import axios from "axios";
import { useEffect, useState } from "react";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import "../css/ManageCompany.css";
import showToast from "./Utils/showToast";
import ConfirmationModal from "./Utils/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const Teams = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTeams, setCurrentTeams] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [temsDelete, setTeamsDelete] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamsForm, setTeamsForm] = useState({
    TeamNo: "",
    NameEntertainer: "",
    AssisgnmentEntertainer: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamsList = async () => {
      try {
        const apiUrl = `${config.apiBaseUrl}Teams`;
        const token = localStorage.getItem("token");
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTeams(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        showToast({ type: "error", message: "Error fetching branches!" });
      }
    };

    fetchTeamsList();
  }, []);
  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  const handleAdd = () => {
    setTeamsForm({
      TeamNo: "",
      AssisgnmentEntertainer: "",
      NameEntertainer: "",
    });
    setIsAdding(true);
    setIsAddModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamsForm({
      ...teamsForm,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      if (isAdding) {
        const response = await axios.post(
          `${config.apiBaseUrl}Teams`,
          teamsForm,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeams((prevTeams) => [...prevTeams, response.data]);
        showToast({ type: "success", message: "Team added successfully!" });
      } else if (isEditing) {
        const id = currentTeams.teamId;
        const response = await axios.put(
          `${config.apiBaseUrl}Teams/${id}`,
          {
            ...teamsForm,
            teamId: currentTeams.teamId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        showToast({ type: "success", message: "Team updated successfully!" });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      showToast({ type: "error", message: "Error submitting data!" });
    } finally {
      setIsAdding(false);
      setIsEditing(false);
      setTeamsForm({
        TeamNo: "",
        AssisgnmentEntertainer: "",
        NameEntertainer: "",
      });
      setIsAddModalOpen(false);
    }
  };

  const handleEdit = (team) => {
    setCurrentTeams(team);
    setTeamsForm({
      TeamNo: team.teamNo,
      AssisgnmentEntertainer: team.assisgnmentEntertainer,
      NameEntertainer: team.nameEntertainer,
    });
    setIsEditing(true);
    setIsAddModalOpen(true);
  };

  // const handleView = (teamId, teamNo) => {
  //   if (teamId) {
  //     navigate(`/Teams/${teamId}?teamNo=${teamNo}`);
  //   } else {
  //     console.error("teamId is undefined");
  //   }
  // };

  const handleView = (teamId, teamNo) => {
   
    // Passing both teamId and teamNo as query parameters in the URL
    navigate(`/Teams/${teamId}?teamNo=${teamNo}`);
  };

  const handleDeleteConfirmation = (team) => {
    setTeamsDelete(team.teamId);
    setDeleteMessage(`Are you sure you want to delete "${team.teamNo}"?`);
    setIsDeleteModalOpen(true);
  };
  const deleteTeams = async () => {
    try {
      const token = localStorage.getItem("token");
      const statusUrl = `${config.apiBaseUrl}Teams/${temsDelete}`;
      await axios.delete(statusUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTeams((prevTeams) =>
        prevTeams.filter((team) => team.teamId !== temsDelete)
      );
      showToast({ type: "success", message: "Team deleted successfully!" });
    } catch (error) {
      showToast({ type: "error", message: "Error deleting team!" });
    } finally {
      setIsDeleteModalOpen(false);
      setTeamsDelete(null);
      setDeleteMessage("");
    }
  };
  return (
    <div className="teams-table">
      <div className="d-flex justify-content-around m-3">
        <h2>Teams List</h2>
        <button className="btn btn-primary" onClick={handleAdd}>
          + Add
        </button>
        <button className="btn btn-primary" onClick={() => navigate('/teams-all')}>
          View All Teams
        </button>
      </div>
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Team Number</th>
            <th>Assisgnment Entertainer</th>
            <th>Name Entertainer</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.teamId}>
              <td>{team.teamNo}</td>
              <td>{team.assisgnmentEntertainer}</td>
              <td>{team.nameEntertainer}</td>
              <td>
                <button
                  className="action-button edit-button"
                  onClick={() => handleView(team.teamId, team.teamNo)}
                  title="View team"
                >
                  <i className="fa fa-eye"></i>
                </button>
                <button
                  className="action-button edit-button"
                  onClick={() => handleEdit(team)}
                  title="Edit team"
                >
                  <i className="fa fa-edit"></i>
                </button>
                <button
                  className="action-button delete-button"
                  onClick={() => handleDeleteConfirmation(team)}
                  title="Delete team"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Add/Edit Form in Modal */}
      {isAddModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>{isAdding ? "Add New Team" : "Edit Team"}</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Team Number</label>
                <input
                  type="text"
                  name="TeamNo"
                  value={teamsForm.TeamNo}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Name Entertainer</label>
                <input
                  type="text"
                  name="NameEntertainer"
                  value={teamsForm.NameEntertainer}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Assisgnment Entertainer</label>
                <input
                  type="text"
                  name="AssisgnmentEntertainer"
                  value={teamsForm.AssisgnmentEntertainer}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  {isAdding ? "Add" : "Update"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsAddModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteTeams}
        message={deleteMessage}
      />
    </div>
  );
};
export default Teams;
