import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import config from "../Utils/config"; // Assuming you have a config file for API base URL

// Register chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

function ContractStatus({ onContractCountUpdate }) {
  const [data, setData] = useState([]);
  const [contractCount, setContractCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contractStatusIds, setContractStatusIds] = useState([]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch contract data
    const fetchContractData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data);
        const contractStatusIds = data.map((item) => item.contractStatusId);

        // Optionally, set this array in a state variable if needed
        setContractStatusIds(contractStatusIds);
      } catch (error) {
        setError("Error fetching contract data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContractData();
  }, []);

  // Process the data to group contractStatusId and count occurrences

  // useEffect(() => {
  //   const token = localStorage.getItem('token');

  //   // Fetch contract data based on contractStatusIds
  //   const fetchContractData = async () => {
  //     setLoading(true);
  //     setError(null);

  //     // Assuming contractStatusIds is an array
  //     const statusIds = contractStatusIds.join('&statusId='); // Join the statusIds as query params

  //     try {
  //       const response = await fetch(
  //         `${config.apiBaseUrl}MainDashboard/GetContractListByStatus?statusId=${statusIds}`, // Use the dynamically generated statusId query string
  //         {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }

  //       const data = await response.json();
  //       setContractCount(data); // Assuming you have a setContractCount state to hold the contract data
  //     } catch (error) {
  //       setError('Error fetching contract data: ' + error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (contractStatusIds.length > 0) {
  //     fetchContractData(); // Only call fetch if there are statusIds available
  //   }
  // }, [contractStatusIds]); // The useEffect will run whenever contractStatusIds changes

  console.log("contract count", contractCount);

  useEffect(() => {
    if (data.length > 0) {
      // Initialize the count object
      const contractCounts = {
        Approved: 0,
        Confirmed: 0,
        Booked: 0,
        Quoted: 0,
        Cancelled: 0,
      };

      data.forEach((contract) => {
        // Convert contractStatusId to an array of status IDs (as numbers)
        const statusIds = Array.isArray(contract.contractStatusId)
          ? contract.contractStatusId
          : [contract.contractStatusId];

        // Iterate over each status ID
        statusIds.forEach((statusId) => {
          // Map the numeric statusId to a string status
          switch (statusId) {
            case 2:
              contractCounts.Approved += 1;
              break;
            case 1:
              contractCounts.Confirmed += 1;
              break;
            case 3:
              contractCounts.Booked += 1;
              break;
            case 4:
              contractCounts.Quoted += 1;
              break;
            case 5:
              contractCounts.Cancelled += 1;
              break;
            default:
              break;
          }
        });
      });

      // Set the contract counts state and pass it to the parent via the callback
      onContractCountUpdate(contractCounts);

      // Prepare chart data
      const chartLabels = Object.keys(contractCounts);
      const chartValues = Object.values(contractCounts);

      // Set the chart data state
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Contract Status Distribution",
            data: chartValues,
            backgroundColor: [
              "rgba(75, 192, 192, 0.6)", // Approved
              "rgba(153, 102, 255, 0.6)", // Confirmed
              "rgba(255, 159, 64, 0.6)", // Booked
              "rgba(255, 99, 132, 0.6)", // Quoted
              "rgba(255, 205, 86, 0.6)", // Cancelled
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)", // Approved
              "rgba(153, 102, 255, 1)", // Confirmed
              "rgba(255, 159, 64, 1)", // Booked
              "rgba(255, 99, 132, 1)", // Quoted
              "rgba(255, 205, 86, 1)", // Cancelled
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data, onContractCountUpdate]); // Make sure to include the callback in the dependency array

  // Show loading or error messages if needed
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Contract Status</h2>
      {chartData && (
        <Pie
          data={chartData}
          style={{ maxHeight: "350px" }}
          options={{
            responsive: true,
            layout: {
              padding: {
                top: 20, // Adjust this value to control the top margin for the chart
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Contract Status Distribution",
              },
              legend: {
                position: "top", // Position the legend at the bottom of the chart
              },
            },
          }}
        />
      )}
    </div>
  );
}

export default ContractStatus;
