import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import config from "./Utils/config";
import { TextField, Button } from "@mui/material";
import "../css/ViewTeamAddress.css";
import Loader from "./Utils/loader";
import { PrintFeature } from "./Utils/PrintFeature";
import {
  calculateDistancesAndDurations,
  geocodeAddress,
} from "./Utils/CalculateDistance";
import { loadGoogleMapsAPI } from "./Utils/LoadGoogleMap";
import showToast from "./Utils/showToast";

function ViewTeamAddress() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const teamNo = queryParams.get("teamNo"); // Get teamNo from query params
  const [displayedData, setDisplayedData] = useState([]);
  const [data, setData] = useState([]);
  const [distances, setDistances] = useState([0]);
  const [durations, setDurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const mapInstance = useRef(null);
  const markers = useRef([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const mapRef = useRef(null);

  // Load Google Maps API script
  useEffect(() => {
    const loadAPI = async () => {
      try {
        await loadGoogleMapsAPI(); // Wait for the API to load
        setGoogleMapsLoaded(true); // Set state when loaded
      } catch (err) {
        setError(err);
      }
    };
    loadAPI();
  }, [googleMapsLoaded]);

  // Reset googleMapsLoaded to true every time locations change
  // useEffect(() => {
  //   if (locations.length > 0 && !googleMapsLoaded) {
  //    // setGoogleMapsLoaded(true); // Reset loading state to re-trigger the map load
  //   }
  // }, [locations, googleMapsLoaded]);

  // Fetch team data
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);

        const json = await response.json();
        const filtered = json.filter((team) => team.teamId == id);
        setData(filtered);
      } catch (err) {
        setError(err.message);
        console.error("Fetch error: ", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // Update locations with latitude and longitude
  useEffect(() => {
    const updateLocationsWithCoordinates = async () => {
      if (data.length > 0) {
        setLoading(true);
        try {
          const updatedLocations = await Promise.all(
            data.map(async (team) => {
              const fullAddress = `${team.address}, ${team.city}, ${team.zip}`;
              try {
                const coordinates = await geocodeAddress(fullAddress);
                return {
                  address: team.address,
                  zip: team.zip,
                  city: team.city,
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                };
              } catch (error) {
                console.error(error);
                return null; // Skip failed geocoding
              }
            })
          );
          setLocations(
            updatedLocations.filter((location) => location !== null)
          );
        } catch (error) {
          console.error("Error geocoding addresses:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    updateLocationsWithCoordinates();
  }, [data]);

  // Initialize map and markers

  const mapInitializer = () => {
    if (googleMapsLoaded && locations.length > 0 && mapRef.current) {
      // Only initialize the map if it has not been created yet
      if (!mapInstance.current) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          mapId: config.googleMapId,
          zoom: 10,
          center: { lat: locations[0].lat, lng: locations[0].lng },
        });
  
     
        locations.forEach((location) => {
          new window.google.maps.marker.AdvancedMarkerElement({
            position: { lat: location.lat, lng: location.lng },
            map: mapInstance.current,
            title: `${location.address}, ${location.city}, ${location.zip}`,
          });
        });
      } else {
        // If map already exists, just update center
        mapInstance.current.setCenter({
          lat: locations[0].lat,
          lng: locations[0].lng,
        });
      }
    }
  };
  
  console.log(locations);
  
  useEffect(() => {
    if (locations.length > 0 && googleMapsLoaded) {
      const fetchData = async () => {
        await calculateDistancesAndDurations(locations, setDistances, setDurations);
  
        // Optionally show content after a delay
        setTimeout(() => {
          setVisible(true);
        }, 2000);
  
        mapInitializer(); 
      };
  
      fetchData();
    }
  }, [locations, googleMapsLoaded]);
  

  // Handle PDF generation
  const handlePrint = () => {
    PrintFeature(displayedData, distances, durations);
    showToast({
      type: "success",
      message: "PDF generated successfully!",
    });
  };

  // Handle date change
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    const filtered = newDate
      ? data.filter((team) => team.entryDate === newDate)
      : data;
    setDisplayedData(filtered);
  };

  // Set displayed data initially
  useEffect(() => {
    setDisplayedData(data);
  }, [data]);

  if (loading) {
    return <Loader isLoading={loading} />;
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ backgroundColor: "#F4F7FF", minHeight: "100vh" }}>
      <h1>{teamNo}</h1>
      <div className="team-address-header">
        <TextField
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />

        {visible ? (
          <Button
            onClick={handlePrint}
            variant="contained"
            sx={{ height: "100%" }}
            color="primary"
          >
            Print
          </Button>
        ) : (
          <p>Loading...</p> // Show "Loading..." when data is not loaded
        )}
      </div>
      <div className="team-address-container">
        <div className="team-address-left-container">
          {displayedData.length ? (
            displayedData.map((team, index) => (
              <div className="team-address-left-box" key={index}>
                <h2>
                  Customer Name: {team.firstName} {team.lastName}
                </h2>
                <p>{team.address}</p>
                <p>zip code : {team.zip}</p>
                <p>City : {team.city}</p>
                <p>Distance : {distances[index]} km</p>
                <p>Duration : {durations[index]} Min</p>
                <p>{team.entryDate}</p>
              </div>
            ))
          ) : (
            <div>No team found with this ID.</div>
          )}
        </div>
        <div className="team-address-right-container">
          <div
            id="map"
            ref={mapRef}
            style={{
              width: "90%",
              height: "60vh",
              marginTop: "20px",
              margin: "20px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewTeamAddress;
