import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import config from "../Utils/config";

// Register necessary components of Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BalanceLineChart = ({ setTotalAmout }) => {
  const [alignment, setAlignment] = useState("left");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [timeRange, setTimeRange] = useState("weekly"); // Default to "yearly"

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch data from API
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}Admin/GetAllAmountByAdmin`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const fetchedData = await response.json();
        setData(fetchedData);
        setTotalAmout(fetchedData.totalAmountYTD);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, [setTotalAmout]);

  console.log("Data:", data);

  // Handle alignment changes (weekly, monthly, quarterly, yearly)
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);

      // Set the timeRange based on the selected toggle
      if (newAlignment === "left") setTimeRange("weekly");
      if (newAlignment === "center") setTimeRange("monthly");
      if (newAlignment === "right") setTimeRange("quarterly");
      if (newAlignment === "justify") setTimeRange("yearly");
    }
  };

  // Function to update chart data based on the selected time range
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let chartLabels = [];
      let chartValues = [];

      // Check the selected time range and update chart data accordingly
      switch (timeRange) {
        case "weekly":
          if (data.totalAmountWeek) {
            chartLabels = data.totalAmountWeek.map((item) => item.dayName);
            chartValues = data.totalAmountWeek.map((item) => item.totalBalance);
          }
          break;

        case "monthly":
          if (data.totalAmountMonth) {
            chartLabels = data.totalAmountMonth.map((item) => item.weekStart);
            chartValues = data.totalAmountMonth.map(
              (item) => item.totalBalance
            );
          }
          break;

        case "quarterly":
          if (data.totalAmountQuarter) {
            // Array to map month numbers to month names
            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];

            chartLabels = data.totalAmountQuarter.map(
              (item) => monthNames[item.month - 1]
            );
            chartValues = data.totalAmountQuarter.map(
              (item) => item.totalBalance
            );
          }
          break;

        case "yearly":
          if (data.totalAmountYear) {
            // Array to map month numbers to month names
            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];

            // Map month number to month name (0-based index)
            chartLabels = data.totalAmountYear.map(
              (item) => monthNames[item.month - 1]
            );
            chartValues = data.totalAmountYear.map((item) => item.totalBalance);
          }
          break;

        default:
          chartLabels = [];
          chartValues = [];
      }

      // Update chartData state with new labels and values
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Balance Over Time",
            data: chartValues,
            fill: false,
            borderColor: "rgba(75, 192, 192, 1)",
            tension: 0.1,
          },
        ],
      });
    }
  }, [data, timeRange]); // Re-run this effect when data or timeRange changes

  return (
    <div>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="time range alignment"
      >
        <ToggleButton value="left" aria-label="weekly">
          Week
        </ToggleButton>
        <ToggleButton value="center" aria-label="monthly">
          Month
        </ToggleButton>
        <ToggleButton value="right" aria-label="quarterly">
          Quarter
        </ToggleButton>
        <ToggleButton value="justify" aria-label="yearly">
          Year
        </ToggleButton>
      </ToggleButtonGroup>

      {/* Line Chart */}
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          paddingTop: "20px",
          height: "400px",
          marginBottom: "20px",
        }}
      >
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {chartData ? (
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
              animations: {
                radius: {
                  duration: 400,
                  easing: "linear",
                  loop: (context) => context.active,
                },
              },
              hoverRadius: 8,
              hoverBackgroundColor: "#2196F3",
              interaction: {
                mode: "nearest",
                intersect: false,
                axis: "x",
              },
              plugins: {
                tooltip: {
                  enabled: true, // Enable tooltips
                  mode: "nearest", // Show tooltip at the nearest data point
                  intersect: false, // The tooltip will appear when hovering over the line, not only the points
                  callbacks: {
                    label: function (tooltipItem) {
                      // Customize the tooltip label if necessary
                      return `Contracts: ${tooltipItem.raw}`; // Show the count of contracts
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <p>No data available for the selected range.</p>
        )}
      </div>
    </div>
  );
};

export default BalanceLineChart;
